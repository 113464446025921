.doc-link{
    position:relative;
    padding-left:52px;
    display:block;
    &:before{
        content:"";
        display:block;
        background: url(../img/icons.png) no-repeat;
        width: 40px;
        height: 50px;
        background-position: -125px 0px;  
        position:absolute;
        top:50%;
        left:0px;
        @include centered(y);
    }
}