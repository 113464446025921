.main-sections{

}

.main-sections__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tablets(){
        display: block;    
    }
}

.main-sections__title-link{
    @include tablets(){
        display: block; 
        text-align:center;  
        margin-bottom:20px; 
    }
}