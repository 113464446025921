.section-item{
    display: inline-block;
    text-decoration:none;
    color:#000;
    text-align:center;
    border: solid 1px #cbcbcb;
    border-radius: 5px;
    height:100%;
    transition: 0.7s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.section-item:hover{
    border-color:rgba(87,146,201,0.4);
    background:rgba(87,146,201,0.1);
}

.section-item__img-wrap{
    display: block;
    margin:19px;
}

.section-item__img{
    display: block;
    max-width:100%;
    margin:0 auto;
}

.section-item__name{
    display: block;
    margin:19px;
}