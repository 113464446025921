.footer{
    background: #1f6bb2;
    color: #fff;
    padding:40px 0px;
    @include phones{
        padding:20px 0px;
    } 
}

.footer__item{
    @include phones{
        padding:5px 0px;
    }     
}

.footer__contacts{
    display:flex;
    justify-content: space-between;
    align-items: center;
    @include phones{
        display:block;
        text-align:center;
    } 
}

.footer__bottom{
    padding-top:25px;
    margin-top:30px;
    border-top:solid 1px #5792c9;
    font-size:14px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    @include phones{
        display:block;
        text-align:center;
        padding-top:15px;
        margin-top:15px;
    } 
}

.footer__phone{
    @include tablets{
        display:none;
    }    
}

.footer__counter{
    display: inline-block;
}


.footer__logo{
    @include laptop{
        display:none;
    }
}

