.pagination{
    display:block;
    text-align:left;
    padding:20px 0px;

    @include phones(){
        text-align:center;
    }
}

.pagination__item{
    display:inline-block;
    padding:0px !important;
    margin:0px 5px 0px 0px !important;

    &:last-child{
        margin:0px !important;    
    }
}

.pagination__item:before{
    display:none !important;
}

.pagination__link{
    display:block;
    vertical-align:middle;
    text-align:center;
    color:#000;
    text-decoration:none;
    font-size:14px;
    width:28px;
    line-height:28px;
    border:solid 1px #cbcbcb;
    cursor:pointer;
    border-radius:3px;
    transition:1s;

    &:hover{
        color:#1f6bb2;
        border-color:#1f6bb2;
    }
}

.active .pagination__link{
    background:#1f6bb2;
    color:#fff;
}