/*--Vars--*/
$color-font:#fff; /*-цвет шрифта пунктов меню-*/
$color-font-sub:#fff; /*-цвет шрифта подпунктов меню-*/
$color-bg-active:#5792c9; /*-цвет активных пунктов меню-*/
$padding-item: 15px 20px; /*-отступы пункта меню-*/
$padding-subitem: 15px 20px; /*-отступы подпункта меню-*/

/*--Первый уровень--*/
.top-menu{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style:none;
    margin:0px;
    padding:0px;
    @include laptop(){
        display:none;   
    }
}

.top-menu__item{ 
    position:relative;
    z-index: 2;
}

.top-menu__link{ 
    color:$color-font;
    text-decoration:none;
    display:block;
    padding:$padding-item;
    font-weight: bold;
    font-size:16px;
    text-transform: uppercase;
    @include display{
        font-size:13px;
        font-weight: 400;
        padding:12px 12px;
    }
}

.top-menu__link:hover{ 
    background: $color-bg-active;
}

.active>.top-menu__link{ 
    background: $color-bg-active;
}

.top-menu_arrow{
    padding-right:38px;
    position:relative;
}

.top-menu_arrow:after{
    content:"";
    display: block;
    position:absolute;
    top:50%; right:18px;
    width:10px; height:6px;
    background: url(../img/icons.png) no-repeat;
    background-position: -90px -70px;
    @include centered(y);
}

.top-menu__item:hover ul{ 
    left:0; 
    opacity:1;
}

.top-menu__item:hover .top-menu__link{ 
    background: $color-bg-active;
}

/*--Второй уровень--*/
.top-menu__sub{
    list-style:none;
    position:absolute;
    left:-9999px; 
    opacity:0;
    background:#fff; 
    width:280px;
}

.top-menu__sub-link{
    background: $color-bg-active;
    padding:$padding-subitem;
    color:$color-font-sub;
    display:block;
    border-top:solid 1px #fff;
    text-decoration:none;
}

.top-menu__sub-link:hover{
    text-decoration:underline;
}

.active>.top-menu__sub-link{ 
    text-decoration:underline;
}