.header-contacts{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:20px;
    @include tablets{
        margin-bottom:10px;
    }
}

.header-contacts__descr{
    font-size:20px;  
    @include display{
        font-size:14px;
    } 
    @include laptop{
        display:none;
    }   
}

.header-contacts__delivery{
    padding-left:60px; 
    position:relative;
    & span{
        display:block;
        font-style: italic;
    }
    &:before{
        content:"";
        position:absolute;
        top:50%;
        left:0px;
        background:url(../img/icons.png) no-repeat;
        width:46px;
        height:32px; 
        background-position: 0px -45px; 
        @include centered(y);
    }

    @include display{
        font-size:12px;
    }
    @include laptop{
        display:none;
    } 
}

.header-contacts__logo{
    display:none;
    @include laptop{
        display:block;
        & img{
            max-height:40px;
        }
        
    } 
}

.header-contacts__phone{
    @include phones{
        padding-left:10px;
        padding-right:10px;
        &:before {
            background: none;
        }     
    } 
}