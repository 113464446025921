@mixin centered($axis: 'both') {
  position: absolute;
  @if $axis=='x' {
    left: 50%;
    transform: translateX(-50%);
  }
  @if $axis=='y' {
    top: 50%;
    transform: translateY(-50%);
  }
  @if $axis=='both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @if $axis=='mirror' {
    bottom:50%;
    transform: rotate(180deg) translateY(-50%);
  }
}

//media
@mixin display() {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones() {
  @media screen and (max-width: 574px) {
    @content;
  }
}
