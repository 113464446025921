.phone-box{
    font-weight: bold;
    font-size:20px;
    padding-left:50px;
    position:relative;
    & span{
        font-weight: 400;
        font-size:16px;
        display:block;
        font-style: italic;
    } 
    &:before{
        content:"";
        position:absolute;
        top:50%;
        left:0px;
        background:url(../img/icons.png) no-repeat;
        width:32px;
        height:32px; 
        background-position: -52px -45px;
        @include centered(y);
    }  

    @include display{
        font-size:16px;
        & span{
            font-size:12px;
        } 
    }

    @include phones{
        padding-left:0px;
        &:before {
            background: none;
        }     
    }
     
    & a{
        text-decoration:none;
        color:#000;
    }
}