.photogallery{

}

.photogallery__item{
    margin-bottom:20px;
    @include tablets{
        text-align:center;
    } 
       
}

.photogallery__link{
    display:block; 
    outline: none;
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 10px;
        bottom: 0px;
        right: 10px;
        background: rgba(31, 31, 31, 0.2);
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
    }  
    &:hover:after {
        background: transparent;
    }  
    @include tablets{
        display: inline-block;
        &:after {
            display: none;
        }     
    }   
}

.photogallery__img{
    display:block;
    max-width: 100%;
}