body {
  margin: 0;
  @include ptsans();
  font-size:16px;
  color: #333333;

}

.fs_ptsans{
  @include ptsans();  
}

a{
  color:#1f6bb2;  
}

a:hover{
  text-decoration: none;  
}

h1,h2,h3,h4,h5,h6{
  font-size: 16px; 
  text-transform: uppercase;
  line-height:120%;
  margin:55px 0px 25px;  
  /*padding:30px 0px 10px;*/
}

h1{
  font-size: 28px;
  margin:30px 0px; 
}

h2{
  font-size: 24px;  
}

h3{
  font-size: 20px;  
}

.typography ul li{
  position:relative;
  padding-left:20px;
  line-height:120%;
  margin:10px 0px 0px;
}

.typography ul li:before{
  content: "";
  display:block;
  position: absolute;
  top:8px;
  left:0px;
  width:7px;
  height:7px;
  background:#ffaf35;
  border-radius:30px;
}

.typography p{
  line-height:150%; 
  margin:10px 0px; 
  /*padding:20px 0px 20px;  */ 
}

.typography>*:first-child{
  padding-top:0px;
  margin-top:0px;
}

table {
    font-size: 16px;
	background: #f5f5f5;
	margin:20px 0px 20px ;
	
	@include display(){
		font-size:14px;
		margin:15px 0px 15px ;
	} 
	
	@include laptop(){
		font-size:12px;
		margin:10px 0px 10px ;
    } 
}

table th{
	border:solid 1px #e2e6e7;
	padding:18px 20px;
}

table td{
	border:solid 1px #e2e6e7;
	padding:15px 20px;
	@include display(){
		padding:8px 10px;
    } 
}

table tr:nth-child(2n+2) {
    background: #fff; 
}

.button{
  background:#ffaf35;
  text-decoration:none;
  color:#fff;
  padding:12px 18px;
  font-weight:bold;
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
  text-transform:uppercase;
  line-height:100%;
  box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2);
  outline:none;

  &:hover{
    background-color:#efa415;
  }

  @include tablets(){
    padding:8px 15px;
  }
}

