.fixed-m-header{
    position:fixed;
    width:100%;
    /*background: url(../img/pattern-head.png);
    background: rgba(87,146,201,0.7);*/
    background: rgba(0,0,0,0.7);
    top:-100px;
    left:0px;
    padding:10px;
    z-index:4;
    &>div{
        padding:0px;
        margin:0 auto;
        @include laptop{
            max-width:700px;    
        }
        @include tablets{
            max-width:520px;    
        }
    }
}