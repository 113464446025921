.header{
    background:url(../img/pattern-head.png);
    color:#fff;
    padding:30px 0px;
    @include tablets{
        padding:15px 0px;
    }
}

.header__logo{
    @include laptop{
        display:none;
    }
}