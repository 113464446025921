.side-menu{

}

.side-menu__item{

}

.side-menu__link{
    background: #1f6bb2;
    color: #fff;
    text-decoration: none;
    font-size:14px;
    padding:20px;
    display: block;
    position: relative;
    line-height: 100%;
    font-weight:bold;
}

.side-menu__inside{

}

.side-menu__inside-item{
    background: #f8f8f8;
    line-height: 100%;
    font-size:14px;
    &.active .side-menu__inside-link{
        color: #1f6bb2;
    }
}

.side-menu__inside-link{
    text-decoration:none;
    display:block;
    border-bottom:solid 1px #fff;
    padding:20px;
    color: #1f1f1f;
    &:hover{
        color: #1f6bb2;
    }

    
    
}