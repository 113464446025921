.special-title{
    font-size:24px;
    color:#000;
    font-weight:bold;
    text-transform: uppercase;
    position:relative; 
    padding-bottom:14px; 
    margin: 43px 0px 25px;
    line-height:120%;
    &:after{
        content:"";
        display:block;
        position:absolute;
        bottom:0px;
        left:0px;
        width:80px;
        height:3px;
        background:#5792c9;
    }  
    @include tablets(){
        margin: 40px 0px 10px;  
    }
}

.special-title_icon{
    padding-left:60px;
    &:before{
        content:"";
        display:block;
        position:absolute; 
        top:5px;
        left:0px;
        width:40px;
        height:40px;
        background: url(../img/icons.png) no-repeat;     
    }   
    &:after{
        left:60px;
    }    
}

.special-title_star{
    &:before{
        background-position: 0px 0px;      
    }      
}

.special-title_settings{
    &:before{
        background-position: -45px 0px;      
    }      
}