.menu-picture{
    margin-top:40px;
    @include tablets(){
        margin-top:20px;
    }
}

.menu-picture__item{
    position:relative;
    display:block; 
    color:#fff;
    text-decoration: none; 
    margin-bottom:20px;
    
}

.menu-picture__item:hover .menu-picture__wrap{
    background: rgba(0,0,0,0.5);     
}
.menu-picture__item:hover .menu-picture__box{
    border-color:rgba(255,255,255,0.5);  
    top:5px; bottom:5px;
    left:5px; right:5px;   
}

.menu-picture__img{
    display:block;
    max-width:100%;
}

.menu-picture__wrap{
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background: rgba(7,41,72,0.5);   
    transition:1s;   
}



.menu-picture__box{
    border:solid 1px #fff; 
    position:absolute;
    top:15px; bottom:15px;
    left:15px; right:15px;
    padding:5px; 
    transition:0.5s;  
    @include phones(){
        top:5px; bottom:5px;
        left:5px; right:5px;
    }
}

.menu-picture__text-box{
    text-align:center; 
    position:absolute;
    left:0px;
    top:50%;
    width:100%;
    @include centered(y);
}

.menu-picture__text{
    display:inline-block;
    font-size:20px;
    font-weight:bold;
    text-transform: uppercase;
    padding-left:44px;
    position:relative;

    @include laptop(){
        font-size:15px;
    }

    @include phones(){
        font-size:14px;
        font-weight:400;
        padding-left:40px;
    }

    &:before{
        content: "";
        display: block;
        position: absolute;
        top:50%;
        left:0px;
        @include centered(y);
        background: url(../img/icons.png) no-repeat;
        width: 30px;
        height: 30px;
        background-position: 0px 0px;

    }
}

.menu-picture_repair{
    &:before{
        background-position: -90px 0px;  
    }  
}

.menu-picture_conversion{
    &:before{
        background-position: -90px -35px;    
    } 
}