.form-field{
    display: flex;
    flex-direction: column;
}

.form-field__name{
    font-size:14px;
    margin-bottom:7px;
}

.form-field__edit{
    padding:9px 10px;
    outline: none;
    border:solid 1px #cbcbcb;
    width: 100%;
    @include ptsans;
    font-size: 14px;
    border-radius: 3px;
    &:focus{
        border-color: #1f6bb2;
    }
}

.form-field_selected{
    position:relative;
    
    
    &:after{
        content:"\f0d7";
        position: absolute;
        @include centered(y);
        right:20px;
        color:#c2c5c8;
        cursor:pointer;
    }

    &.active:after{
        color:#1f6bb2;
    }

    & input{
        cursor:pointer;
    }

    &.active input{
        border:solid 1px #1f6bb2;
    }

    &:hover input{
        border:solid 1px #1f6bb2;
    }

    & .form-field__edit{
        padding: 13px 40px 13px 15px;
    }
}

.form-field__selected{
    position: absolute;
    top:45px;
    left:0px;
    width:100%;
    z-index: 3;
    display:none; 
}



.form-field__selected-list{
    border:solid 1px #1f6bb2;
    background:#fff;
    max-height: 200px;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
}

.form-field__selected-item{
    padding:12px 15px;
    cursor:pointer;
    border-bottom: solid 1px #eceff1;
    font-size: 14px;

    &:last-child{
        border-bottom: none;
    }

    &:hover{
        color: #1f6bb2;
    }
}

.form-field_active{
    background:#eceff1;
    &:hover{
        color: #000;
    }
}




.form-field__edit::-webkit-input-placeholder {color:#c2c5c8;}
.form-field__edit:-moz-placeholder {color:#c2c5c8;}
.form-field__edit::-moz-placeholder {color:#c2c5c8;}
.form-field__edit:-ms-input-placeholder {color:#c2c5c8;}



.error{
	border: solid 1px #E78E8E !important;
}
.error::-webkit-input-placeholder {color:#E78E8E;}
.error:-moz-placeholder {color:#E78E8E;}
.error::-moz-placeholder {color:#E78E8E;}
.error:-ms-input-placeholder {color:#E78E8E;}