.search__field{
    display: flex;
    flex-direction: column;
    position:relative;
}
.search__button{
    display:block;
    position:absolute;
    top:50%;
    right:20px;
    background:url(../img/icons.png) no-repeat;
    width:20px;
    height:20px; 
    background-position: 0px -82px;
    @include centered(y);
    z-index:1;
    cursor: pointer;
    border:none;
    outline:none;
    @include tablets{
        right:5px;
    }
}

.search__edit{
    padding:12px 45px 12px 22px;
    outline: none;
    border:none;
    width: 100%;
    @include ptsans;
    font-size: 16px;
    border-radius: 3px;
    font-style: italic;
    box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.2);
    @include tablets{
        padding:7px 25px 7px 10px;
    }
}

.search__edit::-webkit-input-placeholder {color:#cbcbcb;}
.search__edit:-moz-placeholder {color:#cbcbcb;}
.search__edit::-moz-placeholder {color:#cbcbcb;}
.search__edit:-ms-input-placeholder {color:#cbcbcb;}