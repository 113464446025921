.inside-page{
    padding:40px 0px;
}

.inside-page__left{
    
    @include laptop{
        display:none;
    }
    & img{
        max-width:100%;
    }
}

.inside-page__right{
    
}