#breadcrumb {
    margin: 0px 0px 0px;
    font-size: 14px;
    padding: 0;
    color: #1f6bb2;
    line-height: 120%;

    @include tablets(){
        font-size:14px;
    }
}

#breadcrumb li {
    display: inline-block;
    margin:0px;
    padding: 0px 4px 0px 0px !important;
    background:none;
    
    &:before {
        display: none;
    }

    &:after {
        content: "/";
        display: inline-block;
    }

    &:last-child:after {
        content: "";
    }

    & a {
        margin-right: 7px;
        display: inline-block;
        color: #1f6bb2;
        text-decoration: underline;

    } 

    & a:hover {
        text-decoration: none;
    }

}
