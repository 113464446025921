@charset "UTF-8";
/*
v2.0 | 20110126
License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

ul {
  display: block;
  margin: 0px;
  padding: 0px; }

ul li {
  display: block;
  margin: 0px;
  padding: 0px; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.fancybox-close-small {
  outline: none !important; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/FontAwesome/fontawesome-webfont.eot?v=4.7.0");
  src: url("../fonts/FontAwesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../fonts/FontAwesome/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../fonts/FontAwesome/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../fonts/FontAwesome/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../fonts/FontAwesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  color: #333333; }

.fs_ptsans {
  font-family: 'PT Sans', sans-serif; }

a {
  color: #1f6bb2; }

a:hover {
  text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 120%;
  margin: 55px 0px 25px;
  /*padding:30px 0px 10px;*/ }

h1 {
  font-size: 28px;
  margin: 30px 0px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 20px; }

.typography ul li {
  position: relative;
  padding-left: 20px;
  line-height: 120%;
  margin: 10px 0px 0px; }

.typography ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 0px;
  width: 7px;
  height: 7px;
  background: #ffaf35;
  border-radius: 30px; }

.typography p {
  line-height: 150%;
  margin: 10px 0px;
  /*padding:20px 0px 20px;  */ }

.typography > *:first-child {
  padding-top: 0px;
  margin-top: 0px; }

table {
  font-size: 16px;
  background: #f5f5f5;
  margin: 20px 0px 20px; }
  @media screen and (max-width: 1200px) {
    table {
      font-size: 14px;
      margin: 15px 0px 15px; } }
  @media screen and (max-width: 992px) {
    table {
      font-size: 12px;
      margin: 10px 0px 10px; } }

table th {
  border: solid 1px #e2e6e7;
  padding: 18px 20px; }

table td {
  border: solid 1px #e2e6e7;
  padding: 15px 20px; }
  @media screen and (max-width: 1200px) {
    table td {
      padding: 8px 10px; } }

table tr:nth-child(2n+2) {
  background: #fff; }

.button {
  background: #ffaf35;
  text-decoration: none;
  color: #fff;
  padding: 12px 18px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
  text-transform: uppercase;
  line-height: 100%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  outline: none; }
  .button:hover {
    background-color: #efa415; }
  @media screen and (max-width: 768px) {
    .button {
      padding: 8px 15px; } }

/*!
 * Bootstrap Grid v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

#breadcrumb {
  margin: 0px 0px 0px;
  font-size: 14px;
  padding: 0;
  color: #1f6bb2;
  line-height: 120%; }
  @media screen and (max-width: 768px) {
    #breadcrumb {
      font-size: 14px; } }

#breadcrumb li {
  display: inline-block;
  margin: 0px;
  padding: 0px 4px 0px 0px !important;
  background: none; }
  #breadcrumb li:before {
    display: none; }
  #breadcrumb li:after {
    content: "/";
    display: inline-block; }
  #breadcrumb li:last-child:after {
    content: ""; }
  #breadcrumb li a {
    margin-right: 7px;
    display: inline-block;
    color: #1f6bb2;
    text-decoration: underline; }
  #breadcrumb li a:hover {
    text-decoration: none; }

.category-menu {
  display: block;
  width: 100%; }

.category-menu_otstup {
  margin-bottom: 20px; }

.category-menu__head {
  padding: 18px 20px;
  text-transform: uppercase;
  color: #fff;
  background: #1f6bb2;
  font-size: 16px;
  font-weight: bold; }

/*--пункты меню--*/
.category-menu__item {
  display: block;
  border-bottom: solid 1px #fff; }
  .category-menu__item:last-child {
    border-bottom: none; }
  .category-menu__item.active > a {
    color: #246eb4; }
  .category-menu__item.active_side-menu > a {
    color: #fff;
    background: #246eb4;
    font-weight: bold; }

.category-menu_sub > a {
  padding-right: 30px;
  position: relative; }
  .category-menu_sub > a:after {
    content: "+";
    font-family: fontawesome;
    font-size: 11px;
    color: #ccc;
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 50px; }

.category-menu_sub.active > a:after {
  content: "-";
  border-color: #246eb4;
  color: #246eb4; }

.category-menu__link {
  display: block;
  padding: 17px 20px;
  background: #f8f8f8;
  text-decoration: none;
  font-size: 14px;
  color: #000; }
  .category-menu__link:hover {
    color: #1f6bb2; }

/*--подпункты меню--*/
.category-menu__sub-item {
  display: block;
  border-top: solid 1px #fff; }
  .category-menu__sub-item.active > a {
    color: #246eb4; }
  .category-menu__sub-item.active_side-menu > a {
    color: #fff;
    background: #246eb4;
    font-weight: bold; }

.category-menu__sub-link {
  display: block;
  padding: 15px 12px 15px 40px;
  background: #f8f8f8;
  text-decoration: none;
  font-size: 14px;
  color: #000; }
  .category-menu__sub-link:hover {
    color: #1f6bb2; }

.doc-link {
  position: relative;
  padding-left: 52px;
  display: block; }
  .doc-link:before {
    content: "";
    display: block;
    background: url(../img/icons.png) no-repeat;
    width: 40px;
    height: 50px;
    background-position: -125px 0px;
    position: absolute;
    top: 50%;
    left: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

.fixed-m-header {
  position: fixed;
  width: 100%;
  /*background: url(../img/pattern-head.png);
    background: rgba(87,146,201,0.7);*/
  background: rgba(0, 0, 0, 0.7);
  top: -100px;
  left: 0px;
  padding: 10px;
  z-index: 4; }
  .fixed-m-header > div {
    padding: 0px;
    margin: 0 auto; }
    @media screen and (max-width: 992px) {
      .fixed-m-header > div {
        max-width: 700px; } }
    @media screen and (max-width: 768px) {
      .fixed-m-header > div {
        max-width: 520px; } }

.footer {
  background: #1f6bb2;
  color: #fff;
  padding: 40px 0px; }
  @media screen and (max-width: 574px) {
    .footer {
      padding: 20px 0px; } }

@media screen and (max-width: 574px) {
  .footer__item {
    padding: 5px 0px; } }

.footer__contacts {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 574px) {
    .footer__contacts {
      display: block;
      text-align: center; } }

.footer__bottom {
  padding-top: 25px;
  margin-top: 30px;
  border-top: solid 1px #5792c9;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 574px) {
    .footer__bottom {
      display: block;
      text-align: center;
      padding-top: 15px;
      margin-top: 15px; } }

@media screen and (max-width: 768px) {
  .footer__phone {
    display: none; } }

.footer__counter {
  display: inline-block; }

@media screen and (max-width: 992px) {
  .footer__logo {
    display: none; } }

.form-field {
  display: flex;
  flex-direction: column; }

.form-field__name {
  font-size: 14px;
  margin-bottom: 7px; }

.form-field__edit {
  padding: 9px 10px;
  outline: none;
  border: solid 1px #cbcbcb;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  border-radius: 3px; }
  .form-field__edit:focus {
    border-color: #1f6bb2; }

.form-field_selected {
  position: relative; }
  .form-field_selected:after {
    content: "\f0d7";
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: #c2c5c8;
    cursor: pointer; }
  .form-field_selected.active:after {
    color: #1f6bb2; }
  .form-field_selected input {
    cursor: pointer; }
  .form-field_selected.active input {
    border: solid 1px #1f6bb2; }
  .form-field_selected:hover input {
    border: solid 1px #1f6bb2; }
  .form-field_selected .form-field__edit {
    padding: 13px 40px 13px 15px; }

.form-field__selected {
  position: absolute;
  top: 45px;
  left: 0px;
  width: 100%;
  z-index: 3;
  display: none; }

.form-field__selected-list {
  border: solid 1px #1f6bb2;
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.form-field__selected-item {
  padding: 12px 15px;
  cursor: pointer;
  border-bottom: solid 1px #eceff1;
  font-size: 14px; }
  .form-field__selected-item:last-child {
    border-bottom: none; }
  .form-field__selected-item:hover {
    color: #1f6bb2; }

.form-field_active {
  background: #eceff1; }
  .form-field_active:hover {
    color: #000; }

.form-field__edit::-webkit-input-placeholder {
  color: #c2c5c8; }

.form-field__edit:-moz-placeholder {
  color: #c2c5c8; }

.form-field__edit::-moz-placeholder {
  color: #c2c5c8; }

.form-field__edit:-ms-input-placeholder {
  color: #c2c5c8; }

.error {
  border: solid 1px #E78E8E !important; }

.error::-webkit-input-placeholder {
  color: #E78E8E; }

.error:-moz-placeholder {
  color: #E78E8E; }

.error::-moz-placeholder {
  color: #E78E8E; }

.error:-ms-input-placeholder {
  color: #E78E8E; }

.form-page .form-page__field:last-child {
  margin-bottom: 0px; }

.form-page__col {
  margin-bottom: 10px; }

.form-page__field {
  margin-bottom: 15px; }

.form-page__agree {
  font-size: 14px; }

.form-page_always {
  color: #ea3b0e;
  font-size: 18px; }

.form-page__buttons {
  text-align: right;
  margin-top: 15px; }

.header-contacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .header-contacts {
      margin-bottom: 10px; } }

.header-contacts__descr {
  font-size: 20px; }
  @media screen and (max-width: 1200px) {
    .header-contacts__descr {
      font-size: 14px; } }
  @media screen and (max-width: 992px) {
    .header-contacts__descr {
      display: none; } }

.header-contacts__delivery {
  padding-left: 60px;
  position: relative; }
  .header-contacts__delivery span {
    display: block;
    font-style: italic; }
  .header-contacts__delivery:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    background: url(../img/icons.png) no-repeat;
    width: 46px;
    height: 32px;
    background-position: 0px -45px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  @media screen and (max-width: 1200px) {
    .header-contacts__delivery {
      font-size: 12px; } }
  @media screen and (max-width: 992px) {
    .header-contacts__delivery {
      display: none; } }

.header-contacts__logo {
  display: none; }
  @media screen and (max-width: 992px) {
    .header-contacts__logo {
      display: block; }
      .header-contacts__logo img {
        max-height: 40px; } }

@media screen and (max-width: 574px) {
  .header-contacts__phone {
    padding-left: 10px;
    padding-right: 10px; }
    .header-contacts__phone:before {
      background: none; } }

.header {
  background: url(../img/pattern-head.png);
  color: #fff;
  padding: 30px 0px; }
  @media screen and (max-width: 768px) {
    .header {
      padding: 15px 0px; } }

@media screen and (max-width: 992px) {
  .header__logo {
    display: none; } }

.inside-page {
  padding: 40px 0px; }

@media screen and (max-width: 992px) {
  .inside-page__left {
    display: none; } }

.inside-page__left img {
  max-width: 100%; }

.logo__link {
  display: inline-block;
  color: #fff;
  text-decoration: none; }

.logo__img {
  display: block;
  max-width: 100%;
  max-height: 100%; }

.logo__descr {
  text-align: center;
  display: block;
  line-height: 120%;
  margin-top: 10px;
  font-size: 17px;
  text-transform: uppercase; }
  @media screen and (max-width: 992px) {
    .logo__descr {
      display: none; } }

.made-in a {
  color: #fff; }

.main-page {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media screen and (max-width: 574px) {
    .main-page {
      padding-top: 0px; } }
  .main-page .container > *:first-child {
    margin-top: 0px; }

.main-sections__title {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .main-sections__title {
      display: block; } }

@media screen and (max-width: 768px) {
  .main-sections__title-link {
    display: block;
    text-align: center;
    margin-bottom: 20px; } }

.main-slider {
  margin: 0;
  padding: 0;
  overflow: hidden; }

.main-slider_wrap {
  margin-bottom: 40px; }
  @media screen and (max-width: 768px) {
    .main-slider_wrap {
      padding-left: 0px;
      padding-right: 0px; } }

.main-slider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

.main-slider .slides img {
  width: 100%;
  display: block; }

.main-slider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .main-slider .slides {
  display: block; }

* html .main-slider .slides {
  height: 1%; }

.no-js .main-slider .slides > li:first-child {
  display: block; }

/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.main-slider {
  margin: 0 0 0px;
  background: #fff;
  position: relative;
  zoom: 1; }

.main-slider .slides {
  zoom: 1; }

.main-slider .slides {
  zoom: 1; }
  @media screen and (max-width: 992px) {
    .main-slider .slides {
      background: #5792c9; } }
  @media screen and (max-width: 992px) {
    .main-slider .slides {
      background: none; } }

.main-slider .slides li {
  position: relative; }

.main-slider__blackout {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2; }
  @media screen and (max-width: 992px) {
    .main-slider__blackout {
      background: none; } }

.main-slider__info {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;
  z-index: 3; }
  @media screen and (max-width: 992px) {
    .main-slider__info {
      position: static; } }

.main-slider__info-wrap {
  width: 70%;
  margin: 0 auto;
  display: block;
  padding: 30px 0px;
  text-align: center;
  color: #fff; }
  @media screen and (max-width: 992px) {
    .main-slider__info-wrap {
      width: auto;
      padding: 10px 10%;
      background: #5792c9;
      padding-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    .main-slider__info-wrap {
      padding-bottom: 20px;
      padding: 10px 10px; } }

.main-slider__head {
  display: block;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 150%; }
  @media screen and (max-width: 768px) {
    .main-slider__head {
      font-size: 16px; } }

.main-slider__descr {
  display: block;
  line-height: 150%; }
  @media screen and (max-width: 768px) {
    .main-slider__descr {
      font-size: 14px; } }

.main-slider__button {
  display: block;
  margin-top: 30px; }
  @media screen and (max-width: 992px) {
    .main-slider__button {
      margin-top: 10px; } }

.main-slider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 20px; }

.main-slider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1; }

.main-slider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 20px; }

.main-slider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1; }

@media screen and (max-width: 768px) {
  .main-slider .flex-control-nav {
    display: none; } }

@media screen and (max-width: 768px) {
  .main-slider .flex-direction-nav {
    display: none; } }

.menu-picture {
  margin-top: 40px; }
  @media screen and (max-width: 768px) {
    .menu-picture {
      margin-top: 20px; } }

.menu-picture__item {
  position: relative;
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 20px; }

.menu-picture__item:hover .menu-picture__wrap {
  background: rgba(0, 0, 0, 0.5); }

.menu-picture__item:hover .menu-picture__box {
  border-color: rgba(255, 255, 255, 0.5);
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px; }

.menu-picture__img {
  display: block;
  max-width: 100%; }

.menu-picture__wrap {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(7, 41, 72, 0.5);
  transition: 1s; }

.menu-picture__box {
  border: solid 1px #fff;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  padding: 5px;
  transition: 0.5s; }
  @media screen and (max-width: 574px) {
    .menu-picture__box {
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px; } }

.menu-picture__text-box {
  text-align: center;
  position: absolute;
  left: 0px;
  top: 50%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.menu-picture__text {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 44px;
  position: relative; }
  @media screen and (max-width: 992px) {
    .menu-picture__text {
      font-size: 15px; } }
  @media screen and (max-width: 574px) {
    .menu-picture__text {
      font-size: 14px;
      font-weight: 400;
      padding-left: 40px; } }
  .menu-picture__text:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: url(../img/icons.png) no-repeat;
    width: 30px;
    height: 30px;
    background-position: 0px 0px; }

.menu-picture_repair:before {
  background-position: -90px 0px; }

.menu-picture_conversion:before {
  background-position: -90px -35px; }

.mmenu {
  display: none;
  position: relative;
  width: 30px; }
  @media screen and (max-width: 992px) {
    .mmenu {
      display: block; } }

/* ---- MOBILE ---- */
.mmenu a {
  /*width:20px;
	height:20px;
	background:#ccc;
	display:block;
	position:absolute;
	right:10px;
	top:5px;
    z-index:20;  */
  position: absolute; }

.mm-menu.mm-pageshadow:after {
  width: 1px !important; }

.mm-menu.mm-offcanvas {
  z-index: 20 !important; }

.m_header_menu {
  background: center center no-repeat transparent;
  display: block;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 99;
  text-decoration: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.m_header_menu:hover {
  text-decoration: none !important; }

.m_header_menu span {
  display: block;
  height: 4px;
  background: #fff;
  text-decoration: none !important; }

.m_header_menu span:nth-child(2) {
  margin: 5px 0px; }

.mm-counter {
  display: none !important; }

.mm-panels {
  background: #fff !important; }

.mm-panels > .mm-panel > .mm-listview {
  margin: 0px 0px 0px -20px !important; }

.mm-menu {
  background: #616060 !important; }

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: #fff !important; }

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.8) !important; }

.mm-menu > .mm-navbar {
  background: #5792c9 !important; }

#mmenu .blue span {
  background: #eee; }

.mm-listview > li > a {
  padding: 0px !important; }

#mmenu span {
  padding: 10px 30px 10px 10px !important;
  display: block;
  margin-left: 20px;
  white-space: normal;
  border-left: solid 3px transparent; }

#mmenu li.active span {
  border-left: solid 3px #5792c9; }

.mm-listview > li.button_mobile > a {
  padding: 10px 0px !important; }

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: none !important; }

.mm-panels > .mm-panel {
  padding: 0 10px !important; }

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  height: 0px !important; }

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  width: 7px !important;
  height: 7px !important; }

/* ---- END MOBILE ---- */
.order-modal {
  padding: 40px 3vw !important;
  max-width: 500px;
  display: none; }

.order-modal__head {
  margin-top: 0px;
  text-align: center; }

.pagination {
  display: block;
  text-align: left;
  padding: 20px 0px; }
  @media screen and (max-width: 574px) {
    .pagination {
      text-align: center; } }

.pagination__item {
  display: inline-block;
  padding: 0px !important;
  margin: 0px 5px 0px 0px !important; }
  .pagination__item:last-child {
    margin: 0px !important; }

.pagination__item:before {
  display: none !important; }

.pagination__link {
  display: block;
  vertical-align: middle;
  text-align: center;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  width: 28px;
  line-height: 28px;
  border: solid 1px #cbcbcb;
  cursor: pointer;
  border-radius: 3px;
  transition: 1s; }
  .pagination__link:hover {
    color: #1f6bb2;
    border-color: #1f6bb2; }

.active .pagination__link {
  background: #1f6bb2;
  color: #fff; }

.phone-box {
  font-weight: bold;
  font-size: 20px;
  padding-left: 50px;
  position: relative; }
  .phone-box span {
    font-weight: 400;
    font-size: 16px;
    display: block;
    font-style: italic; }
  .phone-box:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    background: url(../img/icons.png) no-repeat;
    width: 32px;
    height: 32px;
    background-position: -52px -45px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  @media screen and (max-width: 1200px) {
    .phone-box {
      font-size: 16px; }
      .phone-box span {
        font-size: 12px; } }
  @media screen and (max-width: 574px) {
    .phone-box {
      padding-left: 0px; }
      .phone-box:before {
        background: none; } }
  .phone-box a {
    text-decoration: none;
    color: #000; }

.photogallery__item {
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .photogallery__item {
      text-align: center; } }

.photogallery__link {
  display: block;
  outline: none; }
  .photogallery__link:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 10px;
    bottom: 0px;
    right: 10px;
    background: rgba(31, 31, 31, 0.2);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .photogallery__link:hover:after {
    background: transparent; }
  @media screen and (max-width: 768px) {
    .photogallery__link {
      display: inline-block; }
      .photogallery__link:after {
        display: none; } }

.photogallery__img {
  display: block;
  max-width: 100%; }

.product-box {
  margin-top: 18px; }

.product-box__info > * {
  margin-top: 0px; }

@media screen and (max-width: 574px) {
  .product-box__info {
    margin-top: 35px; } }

.product-box__prices {
  margin-top: 30px; }

.product-box__price-box {
  padding: 4px 0px; }
  @media screen and (max-width: 574px) {
    .product-box__price-box {
      display: flex;
      justify-content: space-between;
      align-items: center; } }

.product-box__price-text {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px; }
  @media screen and (max-width: 574px) {
    .product-box__price-text {
      font-size: 14px;
      display: block; } }

.product-box__price {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  color: #1f6bb2;
  font-weight: bold; }
  @media screen and (max-width: 574px) {
    .product-box__price {
      font-size: 16px;
      display: block;
      min-width: 50%;
      text-align: right; } }

.product-box__button {
  margin-top: 25px; }

.product-box__deliv {
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-top: 15px; }

.product-box__tabs {
  margin-top: 40px; }

.product-images {
  border: solid 1px #cbcbcb;
  padding: 19px;
  border-radius: 5px; }

.product-images_fancybox .slides li {
  padding: 0px;
  margin: 0px; }
  .product-images_fancybox .slides li:before {
    display: none; }
  .product-images_fancybox .slides li img {
    display: block;
    max-width: 100%; }

.product-images_fancybox .flex-direction-nav {
  display: none; }

.product-images__major img {
  margin: 0 auto; }

.product-images__preview {
  margin-top: 19px !important; }
  .product-images__preview li {
    cursor: pointer;
    margin-right: 5px !important;
    position: relative; }
    .product-images__preview li:hover:before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(87, 146, 201, 0.1);
      border-radius: 0px; }
  .product-images__preview li.flex-active-slide:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: solid 1px #d8e4ef; }
  @media screen and (max-width: 574px) {
    .product-images__preview {
      display: none; } }

.product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #000;
  text-align: center;
  border: solid 1px #cbcbcb;
  border-radius: 5px;
  height: 100%;
  transition: 0.7s;
  padding: 19px; }
  @media screen and (max-width: 574px) {
    .product-item {
      display: inline-block; } }

.product-item:hover {
  background: #f8f8f8; }

.product-item__img-wrap {
  display: block;
  padding-bottom: 19px; }

.product-item__img {
  display: block;
  max-width: 100%;
  margin: 0 auto; }

.product-item__name {
  display: block;
  text-align: left;
  text-decoration: none;
  color: #000;
  padding-bottom: 19px; }

.product-item__top {
  width: 100%; }

.product-item__bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 19px;
  border-top: solid 1px #ccc;
  text-align: left;
  width: 100%; }
  @media screen and (max-width: 1200px) {
    .product-item__bottom {
      justify-content: space-around;
      text-align: center; } }

.product-item__price-box {
  margin-right: 5px; }

.product-item__price-old {
  display: block;
  font-size: 16px;
  color: #5792c9;
  text-transform: uppercase;
  text-decoration: line-through;
  line-height: 110%; }

.product-item__price-new {
  display: block;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 110%; }

@media screen and (max-width: 1200px) {
  .product-item__oreder {
    margin-top: 10px; } }

@media screen and (max-width: 574px) {
  .product-item__oreder {
    margin-top: 0px; } }

.search__field {
  display: flex;
  flex-direction: column;
  position: relative; }

.search__button {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  background: url(../img/icons.png) no-repeat;
  width: 20px;
  height: 20px;
  background-position: 0px -82px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  border: none;
  outline: none; }
  @media screen and (max-width: 768px) {
    .search__button {
      right: 5px; } }

.search__edit {
  padding: 12px 45px 12px 22px;
  outline: none;
  border: none;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  border-radius: 3px;
  font-style: italic;
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 768px) {
    .search__edit {
      padding: 7px 25px 7px 10px; } }

.search__edit::-webkit-input-placeholder {
  color: #cbcbcb; }

.search__edit:-moz-placeholder {
  color: #cbcbcb; }

.search__edit::-moz-placeholder {
  color: #cbcbcb; }

.search__edit:-ms-input-placeholder {
  color: #cbcbcb; }

.section-item {
  display: inline-block;
  text-decoration: none;
  color: #000;
  text-align: center;
  border: solid 1px #cbcbcb;
  border-radius: 5px;
  height: 100%;
  transition: 0.7s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }

.section-item:hover {
  border-color: rgba(87, 146, 201, 0.4);
  background: rgba(87, 146, 201, 0.1); }

.section-item__img-wrap {
  display: block;
  margin: 19px; }

.section-item__img {
  display: block;
  max-width: 100%;
  margin: 0 auto; }

.section-item__name {
  display: block;
  margin: 19px; }

.sections-page__item {
  margin-bottom: 20px;
  text-align: center; }

.side-menu__link {
  background: #1f6bb2;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding: 20px;
  display: block;
  position: relative;
  line-height: 100%;
  font-weight: bold; }

.side-menu__inside-item {
  background: #f8f8f8;
  line-height: 100%;
  font-size: 14px; }
  .side-menu__inside-item.active .side-menu__inside-link {
    color: #1f6bb2; }

.side-menu__inside-link {
  text-decoration: none;
  display: block;
  border-bottom: solid 1px #fff;
  padding: 20px;
  color: #1f1f1f; }
  .side-menu__inside-link:hover {
    color: #1f6bb2; }

.socials__item {
  display: inline-block;
  margin-right: 20px; }
  @media screen and (max-width: 1200px) {
    .socials__item {
      margin-right: 10px; } }

.socials__item:last-child {
  margin-right: 0px; }

.socials__link {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: url(../img/socials.png) no-repeat;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2); }

.socials__link:hover {
  box-shadow: none; }

.social_vk {
  background-position: -45px -45px; }

.social_fb {
  background-position: 0px 0px; }

.social_tw {
  background-position: 0px -45px; }

.social_ok {
  background-position: -45px 0px; }

.special-title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 14px;
  margin: 43px 0px 25px;
  line-height: 120%; }
  .special-title:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 80px;
    height: 3px;
    background: #5792c9; }
  @media screen and (max-width: 768px) {
    .special-title {
      margin: 40px 0px 10px; } }

.special-title_icon {
  padding-left: 60px; }
  .special-title_icon:before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 0px;
    width: 40px;
    height: 40px;
    background: url(../img/icons.png) no-repeat; }
  .special-title_icon:after {
    left: 60px; }

.special-title_star:before {
  background-position: 0px 0px; }

.special-title_settings:before {
  background-position: -45px 0px; }

/*---Vars---*/
/*-Общие-*/
/* обводка таба и содержания */
/*-Содержание-*/
/* отступ для содержания */
/* Цвет текста для содержания */
/* Цвет заливки для содержания */
/*-Вкладка-*/
/* Цвет текста для вкладки */
/* Цвет заливки для вкладки */
/* Отступ для вкладки */
/* Отступ для вкладки */
/*-Активная вкладка-*/
/* Цвет текста для активной вкладки */
/* Цвет заливки для активной вкладки */
/*-Вкладка при наведении-*/
/* Цвет текста для наведеной вкладки */
/* Базовый контейнер табов */
.tabs {
  padding: 0px;
  margin: 0 auto; }

/* Стили секций с содержанием */
.tabs > section {
  display: none;
  padding: 0px;
  background: #fff;
  border: none;
  color: #000; }
  .tabs > section > * {
    margin-top: 0px; }

.tabs > section > p {
  margin: 0 0 5px;
  line-height: 1.5;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeIn; }

/* Описываем анимацию свойства opacity */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Прячем чекбоксы */
.tabs > input {
  display: none;
  position: absolute; }

/* Стили переключателей вкладок (табов) */
.tabs > label {
  display: inline-block;
  margin: 20px 40px 20px 0px;
  padding: 0px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #cbcbcb;
  border: none;
  border-width: 1px 1px 1px 1px;
  background: transparent;
  border-radius: 3px 3px 0 0;
  border-bottom: dashed 1px #ccc; }

/* Шрифт-иконки от Font Awesome в формате Unicode */
.tabs > label:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px;
  display: none; }

.tabs > label[for*="1"]:before {
  content: "\f013"; }

.tabs > label[for*="2"]:before {
  content: "\f085"; }

/* Изменения стиля переключателей вкладок при наведении */
.tabs > label:hover {
  color: #888;
  cursor: pointer; }

/* Стили для активной вкладки */
.tabs > input:checked + label {
  color: #1f6bb2;
  background: transparent;
  border-color: transparent; }

/* Активация секций с помощью псевдокласса :checked */
#tab1:checked ~ #content-tab1, #tab2:checked ~ #content-tab2, #tab3:checked ~ #content-tab3, #tab4:checked ~ #content-tab4 {
  display: block; }

/* Убираем текст с переключателей 
* и оставляем иконки на малых экранах
*/
@media screen and (max-width: 768px) {
  .tabs > label {
    font-size: 0;
    padding: 10px 20px;
    border: solid 1px #cbcbcb;
    border-bottom: dashed 1px #cbcbcb;
    margin-right: 0px;
    margin-bottom: -1px;
    background: #fff; }
  .tabs > section {
    border: solid 1px #5792c9;
    padding: 10px; }
  .tabs > input:checked + label {
    border: solid 1px #5792c9;
    border-bottom: solid 1px #fff; }
  .tabs > label:before {
    margin: 0;
    font-size: 18px;
    display: inline-block; } }

/*--Vars--*/
/*-цвет шрифта пунктов меню-*/
/*-цвет шрифта подпунктов меню-*/
/*-цвет активных пунктов меню-*/
/*-отступы пункта меню-*/
/*-отступы подпункта меню-*/
/*--Первый уровень--*/
.top-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0px;
  padding: 0px; }
  @media screen and (max-width: 992px) {
    .top-menu {
      display: none; } }

.top-menu__item {
  position: relative;
  z-index: 2; }

.top-menu__link {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase; }
  @media screen and (max-width: 1200px) {
    .top-menu__link {
      font-size: 13px;
      font-weight: 400;
      padding: 12px 12px; } }

.top-menu__link:hover {
  background: #5792c9; }

.active > .top-menu__link {
  background: #5792c9; }

.top-menu_arrow {
  padding-right: 38px;
  position: relative; }

.top-menu_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 18px;
  width: 10px;
  height: 6px;
  background: url(../img/icons.png) no-repeat;
  background-position: -90px -70px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.top-menu__item:hover ul {
  left: 0;
  opacity: 1; }

.top-menu__item:hover .top-menu__link {
  background: #5792c9; }

/*--Второй уровень--*/
.top-menu__sub {
  list-style: none;
  position: absolute;
  left: -9999px;
  opacity: 0;
  background: #fff;
  width: 280px; }

.top-menu__sub-link {
  background: #5792c9;
  padding: 15px 20px;
  color: #fff;
  display: block;
  border-top: solid 1px #fff;
  text-decoration: none; }

.top-menu__sub-link:hover {
  text-decoration: underline; }

.active > .top-menu__sub-link {
  text-decoration: underline; }

.top-nav {
  background: #1f6bb2; }
