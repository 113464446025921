.product-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration:none;
    color:#000;
    text-align:center;
    border: solid 1px #cbcbcb;
    border-radius: 5px;
    height:100%;
    transition: 0.7s;
    padding:19px;

    @include phones(){
        display:inline-block;
    }
}

.product-item:hover{
    background:#f8f8f8;
}

.product-item__img-wrap{
    display: block;
    padding-bottom:19px;
}

.product-item__img{
    display: block;
    max-width:100%;
    margin:0 auto;
}

.product-item__name{
    display: block;
    text-align:left;
    text-decoration:none;
    color:#000;
    padding-bottom:19px;
}

.product-item__top{
    width:100%;
}

.product-item__bottom{
    display: flex; 
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-top:19px;
    border-top:solid 1px #ccc;
    text-align:left;
    width:100%;
    @include display(){
        justify-content: space-around;
        text-align:center;
    }
}

.product-item__price-box{
    margin-right:5px;
}

.product-item__price-old{
    display: block;
    font-size:16px;
    color:#5792c9;
    text-transform: uppercase;
    text-decoration: line-through;
    line-height:110%;     
}

.product-item__price-new{
    display: block; 
    font-weight:bold;
    font-size:20px;
    text-transform: uppercase;   
    line-height:110%; 
}

.product-item__oreder{
    @include display(){
        margin-top:10px;
    }    
    @include phones(){
        margin-top:0px;
    } 
}