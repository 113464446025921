.main-slider {
  margin: 0;
  padding: 0;
  overflow:hidden;
}
.main-slider_wrap{
  margin-bottom:40px;   
  @include tablets(){
    padding-left:0px;   
    padding-right:0px;  
    
  }  
}
.main-slider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.main-slider .slides img {
  width: 100%;
  display: block;
}
.main-slider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .main-slider .slides {
  display: block;
}
* html .main-slider .slides {
  height: 1%;
}
.no-js .main-slider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.main-slider {
  margin: 0 0 0px;
  background: #fff;
  position: relative;
  zoom: 1;
}
.main-slider .slides {
  zoom: 1;
}

.main-slider .slides {
  zoom: 1;
  @include laptop(){
    background: #5792c9;     
  }
  @include laptop(){
    background: none;     
  }
}

.main-slider .slides li {
  position:relative;
  
}

.main-slider__blackout{
  background:rgba(0,0,0,0.3);
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  z-index:2;
  @include laptop(){
    background: none;   
  }
}

.main-slider__info{
  background:rgba(0,0,0,0.5);
  position:absolute;
  top:50%;
  @include centered(y);
  left:0px;
  width:100%;
  z-index:3;
  @include laptop(){
    position:static;
     
  }
}

.main-slider__info-wrap{
    width:70%;
    margin:0 auto;
    display:block;
    padding:30px 0px;
    text-align:center;
    color:#fff;
    @include laptop(){
        width:auto;
        padding:10px 10%;
        background: #5792c9; 
        padding-bottom:50px;       
    }

    @include tablets(){
        padding-bottom:20px;   
        padding:10px 10px;    
    }
}

.main-slider__head{
    display:block;
    font-size:24px;
    font-weight:bold;
    text-transform:uppercase;
    line-height:150%;
    @include tablets(){
        font-size:16px;     
    }
}

.main-slider__descr{
    display:block;
    line-height:150%;
    @include tablets(){
        font-size:14px;     
    }
}

.main-slider__button{
    display:block;
    margin-top:30px;
    @include laptop(){
        margin-top:10px;    
    }
    
}

.main-slider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 20px;
}
.main-slider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.main-slider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 20px;
}
.main-slider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.main-slider .flex-control-nav{
    @include tablets(){
        display:none;
    }
}

.main-slider .flex-direction-nav{
    @include tablets(){
        display:none;
    }
}