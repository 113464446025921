.form-page{
    .form-page__field:last-child{
        margin-bottom:0px;
    }        
}

.form-page__col{
    margin-bottom:10px;
}

.form-page__field{
    margin-bottom:15px;
}

.form-page__agree{
    font-size:14px;
    
}

.form-page_always{
    color:#ea3b0e;
    font-size:18px;   
}

.form-page__buttons{
    text-align:right;
    margin-top:15px;
    
}