.logo{
    
}

.logo__link{
    display:inline-block;  
    color:#fff;
    text-decoration: none;
      
}

.logo__img{
    display:block;
    max-width:100%;
    max-height:100%;
}

.logo__descr{
    text-align: center;
    display:block;
    line-height: 120%;
    margin-top:10px;
    font-size:17px;
    text-transform:uppercase;
    @include laptop{
        display:none;        
    } 
}