.socials{
    
}

.socials__item{
    display:inline-block; 
    margin-right:20px;
    @include display{
        margin-right:10px;
    }
       
}

.socials__item:last-child{
    margin-right:0px;   
}

.socials__link{
    display:block;  
    width:40px;
    height:40px;
    border-radius:12px;
    background: url(../img/socials.png) no-repeat;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
}

.socials__link:hover{
    box-shadow: none;
}

.social_vk{
    background-position: -45px -45px;    
}

.social_fb{
    background-position: 0px 0px;    
}

.social_tw{
    background-position: 0px -45px;    
}

.social_ok{
    background-position: -45px 0px;    
}