.order-modal{
    padding: 40px 3vw !important;
    max-width:500px;
    display:none;
}

.order-modal__head{
    margin-top:0px;
    text-align:center;
}

.order-modal__buttons{

}