@font-face {
		  font-family: 'FontAwesome';
		  src: url('../fonts/FontAwesome/fontawesome-webfont.eot?v=4.7.0');
		  src: url('../fonts/FontAwesome/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../fonts/FontAwesome/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../fonts/FontAwesome/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../fonts/FontAwesome/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../fonts/FontAwesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
		  font-weight: normal;
		  font-style: normal;
}

@mixin avesome() {
	font-family: 'FontAwesome';
}


@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext');

@mixin ptsans() {
	font-family: 'PT Sans', sans-serif;
}

