.product-images{
    border:solid 1px #cbcbcb;
    padding:19px; 
    border-radius:5px;   
}

.product-images_fancybox{
  
    & .slides li{
        padding:0px;
        margin:0px;
        &:before{
            display:none;
        }
        & img{
            display:block;
            max-width:100%;
        }
    }
    & .flex-direction-nav{
        display:none;    
    }
}

.product-images__major{
    & img{
        margin:0 auto;
    }     
}

.product-images__preview{
    margin-top:19px !important;
    & li{
        cursor:pointer;
        margin-right:5px !important; 
        position:relative;
        
        &:hover:before{
            content:"";
            display:block; 
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            background:rgba(87,146,201,0.1);
            border-radius: 0px; 
        }   
    } 
    & li.flex-active-slide{
        
        &:after{
            content:"";
            display:block; 
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            border:solid 1px #d8e4ef;   
        }    
    } 
    @include phones(){
        display:none;
    }  
}
