.product-box{
    margin-top:18px;    
}

.product-box__images{

}

.product-box__info{ 
    
    &>*{
        margin-top:0px;
    }
    @include phones(){
        margin-top:35px;
    }
}

.product-box__doc{

}

.product-box__prices{
    margin-top:30px;
}

.product-box__price-box{
    padding:4px 0px;
    @include phones(){
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
}

.product-box__price-text{
    display:inline-block;
    vertical-align: middle;
    padding-right:10px;
    @include phones(){
        font-size:14px;
        display:block;
    }
}

.product-box__price{
    display:inline-block;  
    vertical-align: middle;
    font-size:24px;
    color: #1f6bb2;
    font-weight:bold;  
    @include phones(){
        font-size:16px;
        display:block;
        min-width:50%;
        text-align: right;
    }
}

.product-box__button{
    margin-top:25px;
}

.product-box__deliv{
    font-size:14px;
    color:#000;
    display:inline-block;
    margin-top:15px;
}

.product-box__tabs{
    margin-top:40px;
}