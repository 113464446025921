.category-menu{
    display:block;
    width: 100%;    
}

.category-menu_otstup{
    margin-bottom:20px;  
}

.category-menu__head{
    padding:18px 20px;
    text-transform:uppercase;
    color:#fff;
    background:#1f6bb2;
    font-size:16px;
    font-weight:bold;
}

/*--пункты меню--*/

.category-menu__item{
    display:block;
    border-bottom:solid 1px #fff;
    &:last-child{
        border-bottom:none;    
    }
    &.active>a{
        color:#246eb4;
    } 
    &.active_side-menu>a{
        color:#fff;
        background:#246eb4;
        font-weight:bold;
    } 
}

.category-menu_sub{
    
    &>a{
        padding-right:30px;
        position:relative;
        &:after{
            content:"+";
            font-family: fontawesome;
            font-size:11px;
            color:#ccc;
            display:block;
            position: absolute;
            top:50%;
            right:12px;
            @include centered(y);
            width:16px;
            height:16px;
            line-height:14px;
            text-align:center;
            border:solid 1px #ccc;
            border-radius:50px;
        } 
    }
     
    &.active{
        &>a:after{
            content:"-";
            border-color:#246eb4;
            color:#246eb4;
        }  
    }  
}

.category-menu__link{
    display:block;
    padding:17px 20px;
    background: #f8f8f8;
    text-decoration: none;
    font-size:14px;
    color:#000;
    &:hover{
        color:#1f6bb2;    
    }
    
    
}



/*--подпункты меню--*/

.category-menu__sub{

}

.category-menu__sub-item{
    display:block;
    border-top:solid 1px #fff;
    &.active>a{
        color:#246eb4;
    }  
    &.active_side-menu>a{
        color:#fff;
        background:#246eb4;
        font-weight:bold;
    }    
}

.category-menu__sub-link{
    display:block;
    padding:15px 12px 15px 40px;
    background: #f8f8f8;
    text-decoration: none;
    font-size:14px;
    color:#000;
    &:hover{
        color:#1f6bb2;    
    }    
}
