.mmenu{
    display: none;
    position:relative;
    width:30px;
    @include laptop(){
        display: block;
    }
}

/* ---- MOBILE ---- */



.mmenu a{
	/*width:20px;
	height:20px;
	background:#ccc;
	display:block;
	position:absolute;
	right:10px;
	top:5px;
    z-index:20;  */
    position:absolute;
}


	
.mm-menu.mm-pageshadow:after{
	width:1px !important;
}
	
.mm-menu.mm-offcanvas {
	z-index: 20 !important;
}

.m_header_menu {
    background: center center no-repeat transparent;
    display: block;
    width: 30px;
    position: absolute;
    right:0px;
	top:0px;
    z-index: 99;
    text-decoration:none !important;
    @include centered(y);
}

.m_header_menu:hover{
    text-decoration:none !important;
}

.m_header_menu span{
    display:block;
    height:4px;
    background:#fff;
    text-decoration:none !important;
}

.m_header_menu span:nth-child(2){
    margin:5px 0px;
}

.mm-counter{
	display:none !important;
}

.mm-panels{
	background:#fff !important;
}

.mm-panels>.mm-panel>.mm-listview {
    margin: 0px 0px 0px -20px !important;
}

.mm-menu{
	background:#616060 !important;
	
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar>* {
    color: #fff !important; 
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
    border-color: rgba(255,255,255,.8) !important;
}

.mm-menu>.mm-navbar {
    background: #5792c9 !important;
}

#mmenu .blue>a{
  
}

#mmenu .blue span{
  background:#eee;
}

.mm-listview>li>a{
    padding: 0px !important;
}

#mmenu span {
    padding: 10px 30px 10px 10px !important;
    display:block;
    margin-left:20px;
    white-space: normal;
    border-left: solid 3px transparent;
}

#mmenu li.active span {
    border-left: solid 3px #5792c9;
}

.mm-listview>li.button_mobile>a{
    padding:10px 0px !important;
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), .mm-menu .mm-listview>li.mm-selected>span {
    background: none !important;
}

.mm-panels>.mm-panel {
    padding: 0 10px !important;
}

.mm-panels>.mm-panel:after, .mm-panels>.mm-panel:before {
    height: 0px !important;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
    content: '';
    width: 7px !important;
    height: 7px !important;
}







/* ---- END MOBILE ---- */