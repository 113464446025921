/*---Vars---*/
    /*-Общие-*/
    $border-general:none;/* обводка таба и содержания */

    /*-Содержание-*/
    $padding-content:0px;/* отступ для содержания */
    $color-text-content:#000;/* Цвет текста для содержания */
    $color-background-content:#fff;/* Цвет заливки для содержания */
    
    /*-Вкладка-*/
    $color-text-tab:#cbcbcb;/* Цвет текста для вкладки */
    $color-background-tab:transparent;/* Цвет заливки для вкладки */
    $padding-tab:0px;/* Отступ для вкладки */
    $margin-tab:20px 40px 20px 0px;
    $font-size-tab:20px;/* Отступ для вкладки */

    /*-Активная вкладка-*/
    $color-text-active:#1f6bb2;/* Цвет текста для активной вкладки */
    $color-background-active:transparent;/* Цвет заливки для активной вкладки */

    /*-Вкладка при наведении-*/
    $color-text-hover:#888;/* Цвет текста для наведеной вкладки */


    
/* Базовый контейнер табов */
.tabs {
	padding: 0px;
	margin: 0 auto;
}
/* Стили секций с содержанием */
.tabs>section {
	display: none;
	padding: $padding-content;
	background: $color-background-content ;
    border: $border-general;
    color: $color-text-content;

    &>*{
        margin-top:0px;
    }
}
.tabs>section>p {
	margin: 0 0 5px;
	line-height: 1.5;
	
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: fadeIn;
}
/* Описываем анимацию свойства opacity */
 
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
/* Прячем чекбоксы */
.tabs>input {
	display: none;
	position: absolute;
}
/* Стили переключателей вкладок (табов) */
.tabs>label {
	display: inline-block;
	margin: $margin-tab;
    padding: $padding-tab;
    font-size:$font-size-tab;
    text-transform:uppercase;
	font-weight: bold;
	text-align: center;
	color: $color-text-tab;
	border: $border-general;
	border-width: 1px 1px 1px 1px;
	background: $color-background-tab;
    border-radius: 3px 3px 0 0;
    border-bottom:dashed 1px #ccc;
}
/* Шрифт-иконки от Font Awesome в формате Unicode */
.tabs>label:before {
	font-family: fontawesome;
	font-weight: normal;
    margin-right: 10px;
    display:none;
}
.tabs>label[for*="1"]:before {
	content: "\f013";
}
.tabs>label[for*="2"]:before {
	content: "\f085";
}
/* Изменения стиля переключателей вкладок при наведении */
 
.tabs>label:hover {
	color: $color-text-hover;
	cursor: pointer;
}
/* Стили для активной вкладки */
.tabs>input:checked+label {
	color: $color-text-active;
    background: $color-background-active;
    border-color:transparent;
}
/* Активация секций с помощью псевдокласса :checked */
#tab1:checked~#content-tab1, #tab2:checked~#content-tab2, #tab3:checked~#content-tab3, #tab4:checked~#content-tab4 {
	display: block;
}
/* Убираем текст с переключателей 
* и оставляем иконки на малых экранах
*/
 
@include tablets(){
    .tabs>label {
        font-size: 0;
        padding: 10px 20px;
        border:solid 1px #cbcbcb;
        border-bottom:dashed 1px #cbcbcb;
        margin-right:0px;
        margin-bottom:-1px;
        background:#fff;
    }
    .tabs>section {
        border:solid 1px #5792c9;
        padding:10px;
    }
    .tabs>input:checked+label {
        border:solid 1px #5792c9;
        border-bottom:solid 1px #fff;
    }
	.tabs>label:before {
		margin: 0;
        font-size: 18px;
        display:inline-block;
    }
    
}
